import { useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { createEffect, createSignal, For, Show } from 'solid-js';
import {
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	Field,
	Form,
	HiddenFields,
	Label,
	MessageBar,
	Select,
	Option,
} from '@troon/ui';
import { IconCircleCheck } from '@troon/icons/circle-check';
import type { ApiResponse } from '@troon/api-client';

const rewardLevels = [
	{ label: 'Member', value: 'MEMBER' },
	{ label: 'Silver', value: 'SILVER' },
	{ label: 'Gold', value: 'GOLD' },
	{ label: 'Platinum', value: 'PLATINUM' },
] satisfies Array<{ label: string; value: RewardsLevel }>;

type RewardsLevel = ApiResponse<'get', '/v0/operator/user/{rewardsId}'>['points']['rewardsLevel'];

export function OverrideRewardsLevel(props: {
	rewardsId: string;
	level: ApiResponse<'get', '/v0/operator/user/{rewardsId}'>['points']['rewardsLevel'];
}) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(overrideRewardLevel);

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	return (
		<Dialog key="operator-override-rewards-level" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" appearance="secondary">
				Override Rewards Level
			</DialogTrigger>
			<DialogContent header="Override rewards level?" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={overrideRewardLevel}>
							<HiddenFields
								data={{
									rewardsId: props.rewardsId,
								}}
							/>

							<Field name="rewardLevel" required={true}>
								<Label>Rewards Level</Label>
								<Select name="rewardLevel" value={props.level}>
									<For each={rewardLevels}>
										{(level) => (
											<Option value={level.value} selected={props.level === level.value}>
												{level.label}
											</Option>
										)}
									</For>
								</Select>
							</Field>

							<p>Any override will last until the end of {new Date().getFullYear()}.</p>

							<Errors />

							<DialogActions>
								<DialogAction type="submit">Override</DialogAction>
								<DialogAction onClick={() => setShowDialog(false)} appearance="secondary" type="button">
									Cancel
								</DialogAction>
							</DialogActions>
						</Form>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>Rewards level overridden.</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const overrideRewardLevel = clientAction(
	'PATCH',
	'/v0/operator/user/{rewardsId}/level',
	(data) => ({
		params: {
			path: { rewardsId: data.get('rewardsId') as string },
		},
	}),
	{
		revalidate: ['/v0/operator/user/{rewardsId}'],
	},
);
