import { createAsync, useSubmission } from '@solidjs/router';
import { cachedGet, clientAction } from '@troon/api-client';
import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	MessageBar,
	Radio,
	RadioGroup,
	Tag,
	TextField,
} from '@troon/ui';
import { createEffect, createMemo, createSignal, For, Show, Suspense } from 'solid-js';
import dayjs from '@troon/dayjs';
import { IconInfo } from '@troon/icons/info';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../../../components/table';
import { useCurrentFacility } from '../../../../../../providers/root';
import { OverrideRewardsLevel } from './components/override-rewards-level';
import type { ApiResponse } from '@troon/api-client';
import type { RouteSectionProps } from '@solidjs/router';

export default function Transactions(props: RouteSectionProps) {
	const transactions = createAsync(() => getTransactions({ path: { rewardsId: props.params.rewardsId! } }), {
		deferStream: false,
	});

	const facility = useCurrentFacility();
	const user = createAsync(() => getUser({ path: { rewardsId: props.params.rewardsId! } }), { deferStream: false });
	const format = new Intl.NumberFormat('en-us').format;

	return (
		<>
			<Heading as="h1" size="h2" class="grow">
				Reward Points History
			</Heading>

			<div class="flex flex-row justify-stretch overflow-auto rounded border border-neutral bg-white p-4 xl:p-8">
				<ol class="flex w-full flex-row divide-x divide-neutral [&>li:first-child]:ps-0 [&>li]:px-4 [&>li]:xl:px-6">
					<For each={user()?.points.ytdPoints}>
						{(point) => (
							<li class="flex flex-col gap-2">
								<span class="text-sm font-medium text-neutral-700">{point.year}</span>
								<span class="text-xl font-semibold">{format(point.points)}</span>
							</li>
						)}
					</For>
				</ol>
			</div>

			<div class="flex flex-wrap items-center justify-between gap-x-4">
				<Heading as="h1" size="h2" class="grow">
					Transactions
				</Heading>
				<Show when={facility()?.role === 'ADMIN'}>
					<OverrideRewardsLevel rewardsId={props.params.rewardsId!} level={user()?.points.rewardsLevel ?? 'MEMBER'} />
				</Show>
				<RedeemRewards rewardsId={props.params.rewardsId!} />
				<AddTransaction rewardsId={props.params.rewardsId!} />
			</div>
			<Table>
				<Thead>
					<Tr>
						<Th>Date</Th>
						<Th>Facility</Th>
						<Th class="text-end">Amount</Th>
						<Th class="text-end">Points</Th>
						<Th>Type</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Suspense
						fallback={
							<Tr>
								<Td colspan={6} class="text-center">
									<ActivityIndicator />
								</Td>
							</Tr>
						}
					>
						<For
							each={transactions.latest?.transactions}
							fallback={
								<Tr>
									<Td colspan={6} class="text-center italic">
										No history found.
									</Td>
								</Tr>
							}
						>
							{(transaction) => <Transaction {...transaction} rewardsId={props.params.rewardsId!} />}
						</For>
					</Suspense>
				</Tbody>
			</Table>
		</>
	);
}

function Transaction(
	props: ApiResponse<'get', '/v0/operator/user/{rewardsId}/transactions'>['transactions'][number] & {
		rewardsId: string;
	},
) {
	const [dialogOpen, setDialogOpen] = createSignal(false);
	const [showEdit, setShowEdit] = createSignal(false);
	const numberFormatter = new Intl.NumberFormat('en-US').format;

	return (
		<>
			<Tr class="group" onClick={() => setDialogOpen(true)}>
				<Td>{dayjs(props.createdAt).format('MM/DD/YYYY')}</Td>
				<Td>{props.facilityName}</Td>
				<Td class="text-end">{props.transactionType === 'REDEMPTION' ? '' : props.amount.displayValue}</Td>
				<Td class="text-end">{numberFormatter((props.transactionType === 'REDEMPTION' ? -1 : 1) * props.points)}</Td>
				<Td>
					<Tag appearance={props.transactionType === 'REDEMPTION' ? 'danger' : 'info'}>
						{props.transactionType.toLowerCase()}
					</Tag>
				</Td>
				<Td class="text-end">
					<Button
						class="inline-flex"
						size="sm"
						appearance="transparent"
						onClick={() => {
							setDialogOpen(true);
						}}
						aria-controls={dialogOpen() ? props.id : undefined}
						aria-haspopup
					>
						<IconInfo />
						<span class="sr-only">More info</span>
					</Button>
				</Td>
			</Tr>
			<Dialog key="operator-transaction" id={props.id} open={dialogOpen()} onOpenChange={setDialogOpen}>
				<DialogContent header="Transaction Details" headerLevel="h2">
					<Show when={showEdit()}>
						<TransactionForm
							rewardsId={props.rewardsId}
							transaction={props}
							submitLabel="Update"
							isVisible={showEdit()}
							didCancel={() => setShowEdit(false)}
							didSubmit={() => {
								setDialogOpen(false);
								setShowEdit(false);
							}}
						/>
					</Show>
					<Show when={!showEdit()}>
						<ul class="mb-8 flex flex-col gap-1">
							<li>
								<b>Type:</b>{' '}
								<Tag appearance={props.transactionType === 'REDEMPTION' ? 'danger' : 'info'}>
									{props.transactionType.toLowerCase()}
								</Tag>
							</li>
							<li>
								<b>Date:</b> {dayjs(props.createdAt).format('MM/DD/YYYY h:mm a')}
							</li>
							<li>
								<b>Location:</b> {props.facilityName}
							</li>
							<Show when={props.transactionType !== 'REDEMPTION'}>
								<li>
									<b>Amount:</b> {props.amount.displayValue}
								</li>
							</Show>
							<li>
								<b>Points:</b> {numberFormatter((props.transactionType === 'REDEMPTION' ? -1 : 1) * props.points)}
							</li>
							<li>
								<b>Notes:</b> {props.notes || '--'}
							</li>
						</ul>

						<DialogActions>
							<DialogAction onClick={() => setDialogOpen(false)}>Close</DialogAction>
							<div class="w-full grow" />
							<DialogAction onClick={() => setShowEdit(true)}>Edit</DialogAction>
							<DeleteTransaction {...props} rewardsId={props.rewardsId!} />
						</DialogActions>
					</Show>
				</DialogContent>
			</Dialog>
		</>
	);
}

const getTransactions = cachedGet('/v0/operator/user/{rewardsId}/transactions');

function AddTransaction(props: { rewardsId: string }) {
	const [showDialog, setShowDialog] = createSignal(false);

	return (
		<Dialog key="operator-add-redeem-points" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0">Add Points</DialogTrigger>
			<DialogContent height="fit" header="Add Points" headerLevel="h2">
				<TransactionForm
					rewardsId={props.rewardsId}
					submitLabel="Add points"
					isVisible={showDialog()}
					didCancel={() => setShowDialog(false)}
					didSubmit={() => setShowDialog(false)}
				/>
			</DialogContent>
		</Dialog>
	);
}

function TransactionForm(props: {
	rewardsId: string;
	transaction?: ApiResponse<'get', '/v0/operator/user/{rewardsId}/transactions'>['transactions'][number];
	isVisible: boolean;
	submitLabel: string;
	didCancel: () => void;
	didSubmit: () => void;
}) {
	const facility = useCurrentFacility();
	const action = createMemo(() => (props.transaction ? updateTransactionAction : addTransactionAction));
	const submission = createMemo(() => useSubmission(action()));

	const currencySymbol = createMemo(() => {
		return new Intl.NumberFormat('en-US', { style: 'currency', currency: facility()?.facility.currencyCode ?? 'USD' })
			.formatToParts(1)
			.find((x) => x.type === 'currency')?.value;
	});

	createEffect(() => {
		if (!props.isVisible) {
			submission().clear();
		}
	});

	return (
		<Show
			when={submission().result}
			fallback={
				<Form action={action()}>
					<HiddenFields
						data={{
							facilityId: facility()?.facility.id,
							currencyCode: facility()?.facility.currencyCode,
							rewardsId: props.rewardsId,
							tz: facility()?.facility.timezone,
							transactionId: props.transaction?.id,
						}}
					/>

					<Show when={!props.transaction}>
						<TextField name="amount" required>
							<Label>
								Amount ({currencySymbol()}
								{facility()?.facility.currencyCode !== currencySymbol() ? facility()?.facility.currencyCode : ''})
							</Label>
							<Input inputMode="decimal" type="number" />
						</TextField>
					</Show>

					<TextField name="notes">
						<Label>Notes</Label>
						<Input multiline value={props.transaction?.notes ?? ''} />
					</TextField>

					<TextField name="datetime">
						<Label>Transaction Date</Label>
						<Input
							type="datetime-local"
							value={dayjs(props.transaction?.createdAt ?? new Date())
								.tz(facility()?.facility.timezone)
								.format('YYYY-MM-DDTHH:mm')}
						/>
					</TextField>

					<Errors />

					<DialogActions>
						<DialogAction type="submit">{props.submitLabel}</DialogAction>
						<DialogAction onClick={() => props.didCancel()} appearance="secondary" type="button">
							Cancel
						</DialogAction>
					</DialogActions>
				</Form>
			}
		>
			{(result) => (
				<div class="flex flex-col gap-8">
					<MessageBar icon={IconCircleCheck} appearance="brand">
						<p>
							{props.transaction
								? 'Your transaction has been updated'
								: `A transaction for ${result().amount.displayValue} has been added, totaling ${result().points} points.`}
						</p>
					</MessageBar>

					<DialogActions>
						<DialogAction
							type="button"
							onClick={() => {
								submission().clear();
								props.didSubmit();
							}}
						>
							Done
						</DialogAction>
						<Show when={!props.transaction}>
							<DialogAction
								appearance="secondary"
								type="button"
								onClick={() => {
									submission().clear();
								}}
							>
								Add more
							</DialogAction>
						</Show>
					</DialogActions>
				</div>
			)}
		</Show>
	);
}

const addTransactionAction = clientAction(
	'POST',
	'/v0/operator/user/{rewardsId}/transactions',
	(data) => ({
		params: { path: { rewardsId: data.get('rewardsId') as string } },
	}),
	{
		transformer: (data) => ({
			amount: parseFloat(data.get('amount') as string),
			timestamp: {
				epoch: dayjs(data.get('datetime') as string).unix() * 1000,
				tz: data.get('tz') as string,
			},
		}),
		revalidate: ['/v0/operator/user/{rewardsId}/transactions', '/v0/operator/user/{rewardsId}'],
	},
);

const updateTransactionAction = clientAction(
	'PATCH',
	'/v0/operator/user/{rewardsId}/transactions/{transactionId}',
	(data) => ({
		params: {
			path: { rewardsId: data.get('rewardsId') as string, transactionId: data.get('transactionId') as string },
		},
	}),
	{
		transformer: (data) => ({
			amount: parseFloat(data.get('amount') as string),
			timestamp: {
				epoch: dayjs(data.get('datetime') as string).unix() * 1000,
				tz: data.get('tz') as string,
			},
		}),
		revalidate: ['/v0/operator/user/{rewardsId}/transactions', '/v0/operator/user/{rewardsId}'],
	},
);

function RedeemRewards(props: { rewardsId: string }) {
	const facility = useCurrentFacility();
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(redeemRewardsAction);

	const user = createAsync(() => getUser({ path: { rewardsId: props.rewardsId } }), { deferStream: false });
	const rewards = createAsync(() => getRewards({ path: { facilityId: facility()?.facility.slug ?? '' } }), {
		deferStream: false,
	});

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	return (
		<Dialog key="operator-add-redeem-points" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" appearance="secondary">
				Redeem Rewards
			</DialogTrigger>
			<DialogContent height="fit" header="Redeem Rewards" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Suspense fallback={<ActivityIndicator />}>
							<Form action={redeemRewardsAction}>
								<HiddenFields data={{ rewardsId: props.rewardsId }} />
								<RadioGroup name="rewardId">
									<Label>Choose a reward</Label>
									<For each={rewards()?.rewards}>
										{(reward) => (
											<Radio value={reward.id} disabled={(user()?.points.availablePoints ?? 0) < reward.points}>
												<Label>
													{reward.points} points and {reward.cash?.displayValue} {reward.cash?.code}
												</Label>
											</Radio>
										)}
									</For>
								</RadioGroup>

								<Errors />

								<DialogActions>
									<DialogAction type="submit">Redeem</DialogAction>
									<DialogAction onClick={() => setShowDialog(false)} appearance="secondary" type="button">
										Cancel
									</DialogAction>
								</DialogActions>
							</Form>
						</Suspense>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>The Rewards points have been redeemd.</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const getRewards = cachedGet('/v0/operator/facilities/{facilityId}/rewards');
const getUser = cachedGet('/v0/operator/user/{rewardsId}');

const redeemRewardsAction = clientAction(
	'POST',
	'/v0/operator/user/{rewardsId}/redemptions',
	(data) => ({ params: { path: { rewardsId: data.get('rewardsId') as string } } }),
	{ revalidate: ['/v0/operator/user/{rewardsId}/transactions', '/v0/operator/user/{rewardsId}'] },
);

function DeleteTransaction(
	props: ApiResponse<'get', '/v0/operator/user/{rewardsId}/transactions'>['transactions'][number] & {
		rewardsId: string;
	},
) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(deleteTransaction);

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	return (
		<Dialog key="operator-delete-transaction" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" appearance="secondary" action="danger">
				Delete
			</DialogTrigger>
			<DialogContent height="fit" header="Delete transaction?" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={deleteTransaction}>
							<HiddenFields
								data={{
									transactionId: props.id,
									rewardsId: props.rewardsId,
								}}
							/>

							<p>Are you sure you want to delete this transaction? This cannot be undone.</p>

							<Errors />

							<DialogActions>
								<DialogAction type="submit" action="danger">
									Delete
								</DialogAction>
								<DialogAction onClick={() => setShowDialog(false)} appearance="secondary" type="button">
									Cancel
								</DialogAction>
							</DialogActions>
						</Form>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>This transaction has been deleted.</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const deleteTransaction = clientAction(
	'DELETE',
	'/v0/operator/user/{rewardsId}/transactions/{transactionId}',
	(data) => ({
		params: {
			path: { rewardsId: data.get('rewardsId') as string, transactionId: data.get('transactionId') as string },
		},
	}),
	{
		revalidate: ['/v0/operator/user/{rewardsId}/transactions'],
	},
);
